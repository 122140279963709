.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .grid-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 100px;
  }

  .checkbox-container {
    position: relative;
    display: flex;
    align-items: start;
  }

  .row {
    display: flex;
    align-items: start;
    gap: 16px;
    width: 100%;
  }

  .icon img {
    height: 38.75px;
    width: 38.75px;
    border: 1px solid #B3B3B3;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .filter .checkbox-container span {
    font-size: 9px;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    margin: 8px 0px 0px 8px;
  }

  