
.sustainability:hover {
  background-color: #b03edd;
}

.innovation:hover {
  background-color: #603edd;
}

.education:hover {
  background-color: #3e51dd;
}

.business:hover {
  background-color: #3eddca;
}

.supply:hover {
  background-color: #3ea0dd;
}

.infrastructure:hover {
  background-color: #3ed5dd;
}

.climate:hover {
  background-color: #3e86dd;
}

.planning:hover {
  background-color: #953edd;
}

.nature:hover {
  background-color: #3ea0dd;
}

.health:hover {
  background-color: #3e6bdd;
}