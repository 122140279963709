div.filter p {
  font-size: 9px;
  font-weight: 500;
}

.search-controls {
  max-width: 248px;
  flex-grow: 0;
}

.filter label {
  font-size: 14px;
  font-weight: 500;
}

div.search-gallery-root{
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: solid;

  width: 100%;
  max-width: 1036px;
}

.search-controls input[type="text"] {
  color: #3ecddd;
  background: #ffffff;
  border: 1.5px solid #3ecddd;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1em;
  padding-left: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-controls input[type="text"]::placeholder {
  color: #3ecddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-controls .filter {
  margin-bottom: 1em;
  text-align: left;
}

.search-controls .filter label {
  margin-left: 5px;
}

.search-controls input[type="checkbox"] {
  display: inline;
  height: initial;
}

.search-controls p {
  border-bottom: 1px solid;
  margin-bottom: 4px;
  padding-bottom: 2px;
  text-align: left;
}

.gallery-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  max-width: 800px;
  padding-bottom: 100px;
  flex-basis: 220px;
  min-width: 220px;
  flex-grow: 1;
}

.gallery-preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 245px;
  max-height: 400px;
  background-color: #222222;
  cursor: pointer;
}

.gallery-preview img {
  width: 100%;
  height: 111px;
  top: 0;
}

.gallery-preview p {
  margin: 0;
  color: white;
  text-align: left;
}

.gallery-preview span {
  position: absolute;
  border-top: 3rem solid #181818;
  border-right: 3rem solid transparent;
  z-index: 9;
}

.gallery-preview div:has(span) {
  text-align: left;
}

.gallery-preview p:has(img) {
  margin: 0;
}

.gallery-preview p:last-of-type {
  max-height: 4rem;
  text-overflow: ellipsis;
}

.gallery-preview:hover,
.gallery-preview a.button:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.381);
}

.gallery-preview a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
  color: #fff;
  padding: 5px 22px;
  border: 1px solid #3f3f3f;
  border-radius: 6px;
  background: #000000;
  margin: 5px 5px 15px 5px;
  font-size: 12px;
  font-weight: 500;
}

.svg-card {
  position: absolute;
  z-index: 19;
}

.card-item-tags {
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  padding: 0.5rem 1rem 0;
  opacity: 0.6;
}

.card-item-title {
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  font-size: 15px;
}

.card-item-snippet {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 300;
  font-size: 13px;
  color: #ffffff;
  opacity: 0.65;
  padding: 0 1rem;
}

.card-item-snippet p {
  font-size: 13px;
}

.header-container.offline img {
  background-color: #545558;
  border: solid 1px #999999;
}
.header-container.offline h1 {
  color: #999999;
}

.svg-container {
  position: relative;
  display: inline-block;
}

img + svg,
a svg {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}

.hero-container {
  position: relative;
  display: flex;
  width: 100%;
}

.under-construction-header {
  position: absolute;
  top: 10rem;
  width: 100%;
  z-index: 1;
}
.under-construction-header h1 {
  font-size: 42px;
  font-weight: 600;
}
.under-construction-header p {
  font-size: 16px;
  font-weight: 400;
}
.under-construction-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(55, 55, 55, 0.7) 0%, #000000 69.05%);
}
