.App {
  border-radius: 8px;
  background-color: #000000;
}

body {
  background-color: #000000;
}

.Hero-text-bg {
  background: rgb(34, 34, 34);
  background: linear-gradient(
    0deg,
    rgba(34, 34, 34, 1) 10%,
    rgba(34, 34, 34, 0.801295518207283) 100%
  );
}

input {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  margin-bottom: 10px;
  border: 2px solid #d1e1d2;
  border-radius: 4px;
}

input:focus {
  outline: none;
}

* {
  margin: 0;
  font-family: "Roboto";
  color: rgb(255, 255, 255);
  font-size: 16px;
}

.navbar {
  padding: 20px 5em;
  display: flex;
  align-items: center;
  /* max-width: 80%; */
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  background-color: #d8f9ed;
  border-radius: 8px;
}

.navbar h1 {
  color: #f1356d;
  font-size: 2em;
}

.navbar .links {
  margin-left: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.navbar a:hover {
  color: #f1356d;
}

div label {
  text-transform: capitalize;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1356d;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  height: 5em;
  margin-bottom: -1px;
}

.footer p {
  color: #ffffff;
}

.footer h1 {
  color: #ffffff;
  font-size: 2em;
}

body {
  font-family: "Roboto";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 768px) {
  .hide-in-mobile {
    display: none !important;
  }
  .show-in-mobile {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .show-in-mobile {
    display: none !important;
  }
  
  .main-menu a {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: clip;
    overflow: hidden;
  }
}

@media screen and (max-width: 336px) {
  .hide-in-tiny {
    display: none !important;
  }
  .show-in-tiny {
    display: block !important;
  }
}
@media (min-width: 336px) {
  .show-in-tiny {
    display: none !important;
  }
}


.header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: solid;
  margin: 1rem 0 2rem 0;
  padding: 0 1rem 2rem 0;
  border-color: #ffffffa3;
  gap: 1em;
  width: 100%;
  max-width: 1036px;
}

.header-container img {
  background-color: #3ecddd;
  width: 32px;
  height: 32px;
  align-items: center;
  border-radius: 3px;
}

.header-container h1 {
  width: 20%;
  font-style: normal;
  font-size: 22px;
  font-weight: 600;
  gap: 0;
  text-align: left;
}

.header-container p {
  text-align: left;
  font-size: 0.8rem;
  white-space: pre-wrap;
}

.landscape-orient p.card-item-snippet {
  -webkit-line-clamp: 6;
}
.portrait-orient p.card-item-snippet {
  -webkit-line-clamp: 10;
}
.link-card.landscape-orient p.card-item-snippet {
  -webkit-line-clamp: 4;
}
.link-card.portrait-orient p.card-item-snippet {
  -webkit-line-clamp: 6;
}
.gallery-preview div.card-item-snippet {
  -webkit-line-clamp: 8;
}

.card-item-snippet {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: clip;
}

.loading {
  position: absolute;
  width: 100%;
  height: 20px;
  text-align: center;
  z-index: 1;
}
