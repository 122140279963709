.download-table-container {
  background: #1F1F1F;
  font-family: 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
}

.download-table-container table {
  width: 100%;
  border: 1px solid rgba(136, 136, 136, 0.4);
  border-collapse: collapse;
  text-transform: uppercase;
}
 
.download-table-container tr {
  border-top: 1px solid rgba(136, 136, 136, 0.4);
}

.download-table-container th {
  color:#3ECDDD;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  vertical-align: bottom;
  padding: 12px 10px 10px 10px;
}

.download-table-container td {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  padding: 10px;
  opacity: .65;
}

.download-table-container th .table-name {
  font-weight: 300;
  font-size: 30px;
  line-height: 28px;
  height: 65px;
  display: flex;
  align-items: center;
  padding-top: 18px;
  font-style: normal;
}

.download-table-container .col1 {
  padding-left: 50px;
  text-transform: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  opacity: 1;
}

.download-table-container .col1 span{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.download-table-container .col7 {
  padding-right: 50px;
  opacity: 1;
}
.download-button {
  background: #3ECDDD;
  border: 1px solid #3ECDDD;
  border-radius: 6px;
  height: 24px;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
}

.item-top-container {
  display: flex;
}

.thumbnail-container img {
  width: 100%;
  max-height: 304px;
}

.summary-container,
.description-container {
  text-align: left;
  word-wrap: break-word;
}

.description-container { /* TODO delete after accordion is implemented */
  border: 1px solid rgba(136, 136, 136, 0.4);
  padding: 15px;
}

.summary-container {
  padding-left: 50px;
  padding-bottom: 50px;
}

.summary-container h1 {
  font-weight: 300;
  font-size: 42px;
  line-height: 48px;
  opacity: 0.7;
}

.summary-container p,
.description-container * {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.8;
}

.map-container iframe {
  width: 100%;
  height: 95%;
}

.map-container iframe html {
  min-width: 10px;
  min-height: 10px;
}

.accordion-container {
  padding: 50px;
  border: 1px solid rgba(136, 136, 136, 0.4);
  min-height: 150px;
  /* TODO: remove palceholder CSS rules once implemented */
}